exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alimentos-js": () => import("./../../../src/pages/alimentos.js" /* webpackChunkName: "component---src-pages-alimentos-js" */),
  "component---src-pages-cotorreo-js": () => import("./../../../src/pages/cotorreo.js" /* webpackChunkName: "component---src-pages-cotorreo-js" */),
  "component---src-pages-dishes-js": () => import("./../../../src/pages/dishes.js" /* webpackChunkName: "component---src-pages-dishes-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

